import { Grid } from "@material-ui/core";

// TODO make this actually centered
export default ({ children }: { children: any }) => {
  return (
    <Grid container justify="center" style={{ marginTop: 100 }}>
      {children}
    </Grid>
  );
};
