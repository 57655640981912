import Error from "../types/Error";
import firebase from "firebase/app";

type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE";

const clientSideFetcher = ({
  token,
  endpoint,
  method,
  payload,
}: {
  token: string;
  endpoint: string;
  method: HTTPMethod;
  payload?: any;
}) => {
  return fetch(`/api/${endpoint}?token=${token}`, {
    method,
    headers:
      method !== "GET" ? { "Content-Type": "application/json" } : undefined,
    body: method !== "GET" && payload ? JSON.stringify({ payload }) : undefined,
  }).then((r) => r.json());
};

const swrFetcher = ({
  token,
  endpoint,
  method,
  payload,
}: {
  token?: string | null;
  endpoint: string;
  method: HTTPMethod;
  payload?: any;
}) => {
  if (!token) return Promise.resolve();

  let urlParams = "";

  if (payload && method === "GET") {
    let payloadAsParams = new URLSearchParams(payload).toString();
    urlParams += `&${payloadAsParams}`;
  }

  return fetch(`/api/${endpoint}?token=${token}${urlParams}`, {
    method,
    headers:
      method !== "GET" ? { "Content-Type": "application/json" } : undefined,
    body: method !== "GET" && payload ? JSON.stringify({ payload }) : undefined,
  })
    .then((r) => r.json())
    .then(({ data, error }: { data: any; error: Error }) => {
      if (error?.code === "auth/id-token-expired") {
        // Sentry.captureException(error);
        firebase.auth().currentUser?.getIdToken(true);
        return;
      } else if (error) {
        // Sentry.captureException(JSON.stringify(error));
        throw error;
      } else {
        return data;
      }
    });
};

export { clientSideFetcher, swrFetcher };
